<template>
  <v-app >
    <v-container fluid fill-height >
        <v-card elevation="24" outlined tile height="100%" width="100%" style="background-color: #e4e7ec;">
            <v-row>
                <v-col cols="6">
                    <v-card elevation="0" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 250px;color: rgb(38, 57, 89);margin-top: 200px;">{{city}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 145px;color: rgb(38, 57, 89);margin-top: 250px;">{{dateStr}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 170px;color: rgb(38, 57, 89);margin-top: 250px;">{{timeStr}}</v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card elevation="0" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 150px;color: rgb(38, 57, 89);margin-top: 200px;margin-left: 30px;">溫度<span style="color: #6f3826;margin-left: 50px;">{{temperature}}°C</span></v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 150px;color: rgb(38, 57, 89);margin-top: 100px;">濕度<span style="color: #6f3826;margin-left: 50px;">{{humidity}}%</span></v-card-title>
                        <table width="100%" style="margin-top: 100px;" v-if="true">
                            <tr>
                                <td  colspan="4" style="text-align: center;" :class="heatColor">
                                    <span class="font-weight-black" style="font-size: 80px;">熱指數 {{heatindex}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-weight-black hotDetal light-green accent-2" style="font-size: 60px;">
                                    第一級<br/>
                                    注意<br/>
                                    26.7<br/>
                                </td>
                                <td class="font-weight-black hotDetal yellow accent-2"  style="font-size: 60px;">
                                    第二級<br/>
                                    格外注意<br/>
                                    32.2<br/>
                                </td>
                                <td class="font-weight-black hotDetal orange accent-2"  style="font-size: 60px;">
                                    第三級<br/>
                                    危險<br/>
                                    40.6<br/>
                                </td>
                                <td class="font-weight-black hotDetal deep-orange accent-4" style="font-size: 60px;">
                                    第四級<br/>
                                    極度危險<br/>
                                    54.4<br/>
                                </td>
                            </tr>
                        </table>
                    </v-card>
                </v-col>
                <v-col cols="6" v-if="false">
                    <v-card elevation="0" height="100%" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 250px;color: rgb(38, 57, 89);margin-top: 200px;">{{city}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 145px;color: rgb(38, 57, 89);margin-top: 200px;">{{dateStr}}</v-card-title>
                        <v-card-title class="font-weight-black justify-center" style="font-size: 170px;color: rgb(38, 57, 89);margin-top: 200px;">{{timeStr}}</v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="6" v-if="false">
                    <v-card elevation="0" width="100%" class="mt-4" style="background-color:#e4e7ec;">
                        <v-card-title class="font-weight-black justify-center" style="font-size: 95px;color: rgb(38, 57, 89);margin-top: 270px;">溫度<span style="color: #6f3826;margin-right: 30px;">{{temperature}}°C</span>濕度<span style="color: #6f3826;">{{humidity}}%</span></v-card-title>
                        <table width="100%" style="margin-top: 100px;" v-if="true">
                            <tr>
                                <td  colspan="4" style="text-align: center;" :class="heatColor">
                                    <span class="font-weight-black" style="font-size: 80px;">熱指數 {{heatindex}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-weight-black hotDetal light-green accent-2" style="font-size: 60px;">
                                    第一級<br/>
                                    注意<br/>
                                    26.7<br/>
                                </td>
                                <td class="font-weight-black hotDetal yellow accent-2"  style="font-size: 60px;">
                                    第二級<br/>
                                    格外注意<br/>
                                    32.2<br/>
                                </td>
                                <td class="font-weight-black hotDetal orange accent-2"  style="font-size: 60px;">
                                    第三級<br/>
                                    危險<br/>
                                    40.6<br/>
                                </td>
                                <td class="font-weight-black hotDetal deep-orange accent-4" style="font-size: 60px;">
                                    第四級<br/>
                                    極度危險<br/>
                                    54.4<br/>
                                </td>
                            </tr>
                        </table>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-card>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import loadingMask from '@/components/public/loadingMask.vue'
import commonMixin from '@/utils/commonMixin';
import {getHeatindex} from "@/api/hyirecoApi"
import {getWeatherInfo} from "@/api/weatherApi"

export default {
  name: 'dashbord',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    //this.clearCountdown();

    if (!this.checkPlusFunc('thEnable')) {
        this.$swal("目前帳號無開起此功能");
        this.$router.push({ path: "/index" });
    }

    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
    //console.log("loginAuth = " + JSON.stringify(this.loginAuth));
  },
  destroyed() {
    clearInterval(this.tiemTimer);
    this.tiemTimer = null;
    clearInterval(this.dataTimer);
    this.dataTimer = null;
    clearInterval(this.inoutTime);
    this.inoutTime = null;
  },
  mounted(){
    this.getCityData();
    this.getWeather();
    this.showDateTime();
    this.getHeatColor();

    this.tiemTimer = setInterval(() => {
        this.showDateTime();
        this.getWeatherRefresh();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
    },1000);

    // this.dataTimer =  setInterval(() => {
    //     this.getDataList();
    // },3000);

    this.inoutTime = setInterval(() => {
      this.resetCountdown();
      this.getWeather();
      //console.log("vm.loginAuth.timeStart = " + vm.loginAuth.timeStart);
    },1500000);
  },
  methods: {
    getWeatherRefresh: function(){
        var date = new Date();
        var mm = date.getMonth() + 1;

        if (this.nowHour != mm) {
            this.nowHour = mm;
             this.getWeather();
        }
    },
    getWeather: function(){
        var vm = this;
        var date = new Date();
        var hour = parseInt(date.getHours()/3)*3;

        var dateStr = [
            date.getFullYear(),
            ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1),
            (date.getDate() > 9 ? '' : '0') + date.getDate()
        ].join('-');

        var requestStr = "";
        requestStr += "locationName=" + vm.city;
        requestStr += "&elementName=T,RH";
        requestStr += "&timeFrom=" + dateStr + "T" + ((hour > 9 ? '' : '0') + hour.toString()) + ":00:00"

        getWeatherInfo(requestStr).then(function(response) {
            if ("200" == response.status) { 
                //console.log("response = " + JSON.stringify(response.data.records.locations[0].location[0].weatherElement));
                var responseData = response.data.records.locations[0].location[0].weatherElement;
                
                var T = responseData.filter(function(item) {
                    return item.elementName === 'T';
                })[0].time[0].elementValue[0].value;

                var RH = responseData.filter(function(item) {
                    return item.elementName === 'RH';
                })[0].time[0].elementValue[0].value;

                vm.temperature = T;
                vm.humidity = RH;
                console.log("T:" + T + ",RH:" + RH);

                if (parseFloat(T) >= 26.7) {
                    var para = {
                        temperature: T,
                        humidity: RH
                    };
                    
                    getHeatindex(para, vm.auth).then(function(response) {
                        if ("200" == response.status) {
                            var responseData = response.data;
                            console.log("responseData = " + JSON.stringify(responseData));
                            //vm.heatindex
                            if (responseData.statusCode === "1") {
                                vm.heatindex = responseData.heatindex;
                                console.log("heatindex = " + vm.heatindex);
                            }

                            vm.getHeatColor();
                            
                        }
                    }); 
                } else {
                    vm.heatindex = "32.2";
                    vm.getHeatColor();
                }

            }
        });
    },
    getHeatColor: function(){
        var vm = this;
        var intHeat = parseFloat(vm.heatindex);
        if (intHeat < 32.2) {
            vm.heatColor = "light-green accent-2";
        } else if (intHeat < 40.6) {
            vm.heatColor = "yellow accent-2";
        } else if (intHeat < 54.4) {
            vm.heatColor = "orange accent-2";
        } else if (intHeat >= 54.4) {
            vm.heatColor = "deep-orange accent-2";
        }
    },
    getCityData: function() {
        var tmpArr = this.loginAuth.orgnization.location.split(' ');
        this.city = tmpArr.length > 0 ? tmpArr[0] : "";
        //console.log("city = " + tmpArr);
    },
    showDateTime: function() {
        var date = new Date();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();

        this.dateStr = [
            date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
        ].join('-');


        this.timeStr = [
            (date.getHours() > 9 ? '' : "0") + date.getHours(),
            (date.getMinutes() > 9 ? '' : "0") + date.getMinutes(),
            (date.getSeconds() > 9 ? '' : "0") + date.getSeconds()
        ].join(':');

    },
  },
  watch: {
  },
  data: () => ({
    userName: "",
    isLoading: false, //loading畫面的開關
    dataList: {},
    city: "",
    dateStr: "",
    timeStr: "",
    heatindex: "32.2",
    heatColor: "light-green accent-2",
    dataTimer: null,
    tiemTimer: null,
    inoutTime: null,
    memberPropertyList: [],
    temperature: "",
    humidity: "",
    nowHour: 0,
  }),
  components: {
    loadingMask,
  },
}
</script>

<style scoped>
    .hotDetal{
        text-align: center;
        font-size: 30px;
    }

    .column{
        float:left;
        list-style:none; /* 將預設的列表符號去掉 */
        padding:0; /* 將預設的內邊距去掉 */
        margin:0; /* 將預設的外邊距去掉 */
        width: 20%;
        height: 300px;
        min-height: 300px;
    }

    .menu{
        margin: 0;
        padding: 0;
        display:inline-block;
        margin-left: -10px;
        margin-right: 5px;

    }

    .menu li { 
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        width: 20%;
    }
</style>